@font-face {
    font-family: interRegular;
    src: url(Inter-Regular.ttf);
}


body {
    overflow-y: scroll !important;
    color: white;
    font-family: interRegular;
}

h1 {
    text-transform: uppercase;
    letter-spacing: 0.3rem;
    font-size: x-large;
}

h2 {
    color: #AA893F;
    margin-left: 5vh;
    margin-top: 0px !important;
    font-size: x-large;
    font-family: interRegular;
    font-weight: normal;
}

p {
    font-weight: 600;
    font-size: large;
    letter-spacing: 0.1rem;
}

a {
    color: white;
    text-decoration: none;
}

a:hover {
    color: #AA893F;
    transition: 1s;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* LAUNCH LAUNCH LAUNCH */
/* LAUNCH LAUNCH LAUNCH */
/* LAUNCH LAUNCH LAUNCH */

.launch {
    background-image: url('background-images/landing.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.launch h1 {
    position: absolute;
    top: 90vh;
    text-align: center;
}

.logo-gold {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 40px;
    top: 105vh;
}

.logo-caps {
    width: 113px;
    height: 18px;
    position: absolute;
    left: 50px;
    top: 195vh;
}

/* ABOUT ABOUT ABOUT */
/* ABOUT ABOUT ABOUT */
/* ABOUT ABOUT ABOUT */

.about {
    background-image: url('background-images/geometrics.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.bloc1 {
    width: 60%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.bloc2 {
    width: 40%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.bloc2 div {
    width: 462px;
    height: 617px;
}

.bloc2 div img {
    height: 100%;
    width: 100%;
    opacity: 0.6;
}

/* CONTACT CONTACT CONTACT */
/* CONTACT CONTACT CONTACT */
/* CONTACT CONTACT CONTACT */

.contact {
    background-image: url('background-images/bleu-nuit.svg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.img-container {
    height: 684px;
    width: 460px;
    background-image: url('background-images/silhouette.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.img-container h1 {
    margin-top: 16vh;
    letter-spacing: 0.4rem;
    font-size: 48px;
    text-align: center;
    color: #AA893F;
    width: 100%;
}

.logo-caps-bis {
    width: 113px;
    height: 18px;
    position: absolute;
    right: 50px;
    top: 205vh;
}

.logo-gold-bis {
    width: 80px;
    height: 80px;
    position: absolute;
    left: 40px;
    top: 205vh;
}

/* RESPONSIVE RESPONSIVE RESPONSIVE  */
/* RESPONSIVE RESPONSIVE RESPONSIVE  */
/* RESPONSIVE RESPONSIVE RESPONSIVE  */

@media only screen and (max-width : 900px) {
  
    .bloc2 div {
        width: 370px;
        height: 493px;
    }

    h2 {
        font-size: large;
    }

    .img-container {
        height: 547px;
        width: 368px;
    }

    .img-container h1 {
        font-size: 46px;
        width: 130vh;
        margin-top: 20vh;
    }
    
}

@media only screen and (max-width : 450px) {
  
    .launch h1 {
        font-size: medium;
        width: 90%;
    }
    
    .bloc2 div {
        height: 280px;
        width: 200px;
    }
    
    h2 {
        font-size: 14px;
        width: 90%;
        margin-top: 20vh !important;
        margin-left: 0px;
        text-align: center;
    }

    
    .img-container h1 {
        font-size: 26px;
    }

    .img-container {
        margin-bottom: 8vh;
        height: 437px;
        width: 294px;
    }

    p {
        font-size: small;
    }

    .bloc1 {
        width: 100%;
        align-items: center;
    }

    .bloc2 {
        width: 100%;
    }

    .about {
        flex-direction: column;
        align-items: center;
    }

    .launch {
        background-attachment: unset;
    }

    .about {
        background-attachment: unset;
    }
    
    .contact {
        background-attachment: unset;
    }


    .logo-gold {
        width: 64px;
        height: 64px;
        left: 25px;
    }

    .logo-gold-bis {
        width: 64px;
        height: 64px;
        left: 25px;
    }

    .logo-caps {
        width: 80px;
        left: 25px;
    }

    .logo-caps-bis {
        width: 80px;
        left: 25px;
        top: 295vh;
    }
}
  